import styled, { css } from 'styled-components';
import { device, mtrStyle } from 'src/styles/global';
import theme from 'src/styles/theme';
import { Button } from '@dsny/dsny-component-library';

export const Title = styled.h2`
  padding-bottom: 8px;
  color: ${theme.colors.neutralW20};

  @media ${device.mobile} {
    font-size: 20px;
  }
`;

export const SubTitle = styled.div`
  padding-bottom: 24px;
  font-size: 14px;
  color: ${theme.colors.neutralW40};

  @media ${device.mobile} {
    padding-bottom: 24px;
  }
`;

export const PackageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 24px;
  gap: 16px;

  @media ${device.mobile} {
    flex-direction: column;
    align-items: center;
    padding-bottom: 24px;
  }

  @media ${device.tablet} {
    flex-direction: column;
    align-items: center;
    padding-bottom: 24px;
  }
`;

export const PackageContainer = styled.div`
  width: 33%;
  max-height: 230px;

  @media ${device.mobile} {
    width: 100%;
  }

  @media ${device.tablet} {
    width: 100%;
  }
`;

export const PackageBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid ${theme.colors.neutralW80};
  border-radius: ${mtrStyle.borderRadius};
  align-items: center;
`;

export const PlanWrapper = styled.div`
  display: flex;
  align-self: center;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.neutralW20};

  @media ${device.mobile} {
    font-size: 14px;
  }
`;

export const RegularPrice = styled.div`
  display: flex;
  align-self: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  margin-top: 8px;
  color: ${theme.colors.neutralW20};

  @media ${device.mobile} {
    font-size: 20px;
  }
`;

export const TotalPriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: ${theme.colors.neutralW40};
  gap: 4px;

  @media ${device.mobile} {
    font-size: 12px;
  }
`;

const sharedStylesMarkdownPrice = css`
  display: flex;
  align-self: flex-end;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: ${theme.colors.neutralW60};

  @media ${device.mobile} {
    font-size: 12px;
  }
`;

export const MarkdownPrice = styled.div`
  ${sharedStylesMarkdownPrice}
`;

export const PromoMarkdownPrice = styled.div`
  ${sharedStylesMarkdownPrice}
  text-decoration-line: line-through;
`;

export const USDPrice = styled.div`
  display: flex;
  align-self: flex-end;
  font-weight: 700;
  font-size: 12px;
  line-height: 19px;
  margin-left: 4px;
  margin-bottom: 6px;
  color: ${theme.colors.neutralW20};
`;

export const DiscountWrapper = styled.div`
  display: flex;
  align-self: center;
  font-size: 14px;
  margin-top: 8px;
  gap: 8px;
`;

export const SaveBox = styled.div`
  display: flex;
  align-self: center;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  padding: 1px 4px;
  color: ${theme.colors.error50};
  border-radius: 5px;
  border: 1px solid ${theme.colors.error90};
  background-color: ${theme.colors.error90};
`;

export const SelectButton = styled(Button)`
  margin-top: 16px;
  width: 100%;
  svg {
    width: 18px;
    height: 18px;
  }

  @media ${device.tablet} {
    width: 50%;
  }
  @media ${device.mobile} {
    width: 100%;
  }
`;

export const SectionSeparator = styled.div`
  width: 100%;
  border-top: 1px solid ${theme.colors.neutralW80};
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const ChargedNote = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${theme.colors.neutralW40};
  padding-bottom: 24px;
`;
