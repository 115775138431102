import { createSlice, current } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { formatTextForSentryLog } from 'src/utils/Formatter';
import { trackEvent } from 'src/utils/segmentService';
import {
  getPackageList,
  getPromoCodeDiscount,
  getSingleSongPackageList,
} from './SelectPackage.thunks';

interface Plan {
  id: string;
  name: string;
  description: string;
  price: string;
  duration: number;
  discount: number;
}

interface Promotion {
  title: string;
  name: string;
  description: string;
  disclaimer: string;
  discount_percentage: number;
  start_date: string;
  expiration_date: string;
}

interface Summary {
  unit_price: string;
  subtotal: string;
  promo_discount_amount: string;
  unit_price_after_promo: string;
  subtotal_after_promo: string;
  taxes_and_fees: string;
  total: string;
  quantity: number;
}

interface promoCodeError {
  code: number;
  error: string;
}

export interface PackageObj {
  plan: Plan;
  promotion: Promotion;
  summary: Summary;
  releaseId?: string;
}

export interface SelectPackageObj {
  packages: [PackageObj?];
  packagesSingleSong: [PackageObj?];
  selectedPackage?: PackageObj;
  isFetching: boolean;
  hasError: boolean;
  promoCodeActive: string;
  promoCodeError: string;
  releaseId?: string;
}

const getCurrentSelectedPackage = (
  currentPackage: PackageObj,
  newPackages: PackageObj[]
) => {
  const { duration } = currentPackage.plan;
  return newPackages.find((item) => item.plan.duration === duration);
};

const currentAndNewPromotionNotNullValidation = (
  currentPackage: PackageObj,
  newSelectedPackage: PackageObj
) => {
  return (
    newSelectedPackage &&
    currentPackage.promotion === null &&
    newSelectedPackage.promotion !== null
  );
};

const currentPromotionBiggerThanNewPromotionValidation = (
  currentPackage: PackageObj,
  newSelectedPackage: PackageObj
) => {
  return (
    newSelectedPackage &&
    newSelectedPackage.promotion !== null &&
    newSelectedPackage.promotion?.discount_percentage >
      currentPackage.promotion?.discount_percentage
  );
};

const SelectPackageSlice = createSlice({
  name: 'SelectPackage',
  initialState: {
    packages: [],
    packagesSingleSong: [],
    selectedPackage: undefined,
    isFetching: false,
    hasError: false,
    promoCodeActive: '',
    promoCodeError: '',
  } as SelectPackageObj,
  extraReducers: (builder) =>
    builder
      .addCase(getPackageList.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getPackageList.fulfilled, (state, { payload }) => {
        state.packages = payload.data;
        state.hasError = false;
        state.isFetching = false;
        state.promoCodeActive = '';
      })
      .addCase(getPackageList.rejected, (state) => {
        state.hasError = true;
        state.isFetching = false;
        Sentry.captureException(
          formatTextForSentryLog('failed to get package list')
        );
        return state;
      })
      .addCase(getSingleSongPackageList.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getSingleSongPackageList.fulfilled, (state, { payload }) => {
        state.packagesSingleSong = payload.data;
        state.hasError = false;
        state.isFetching = false;
      })
      .addCase(getSingleSongPackageList.rejected, (state) => {
        state.hasError = true;
        state.isFetching = false;
        Sentry.captureException(
          formatTextForSentryLog('failed to get package single song list')
        );
        return state;
      })
      .addCase(getPromoCodeDiscount.pending, (state) => {
        state.isFetching = true;
        state.promoCodeError = '';
      })
      .addCase(getPromoCodeDiscount.fulfilled, (state, action) => {
        state.hasError = false;
        state.isFetching = false;
        const currentPackage = current(state).selectedPackage;
        const newPackages = action.payload.data as [PackageObj];

        if (currentPackage) {
          const newSelectedPackage = getCurrentSelectedPackage(
            currentPackage,
            newPackages
          );

          if (
            (newSelectedPackage &&
              currentAndNewPromotionNotNullValidation(
                currentPackage,
                newSelectedPackage
              )) ||
            (newSelectedPackage &&
              currentPromotionBiggerThanNewPromotionValidation(
                currentPackage,
                newSelectedPackage
              ))
          ) {
            state.packages = action.payload.data;
            state.promoCodeActive = action.meta.arg.promoCode;
          } else if (
            newSelectedPackage &&
            newSelectedPackage.promotion !== null
          ) {
            state.promoCodeError = 'DISCOUNT_CODE_ERROR_LESS_THAN_APPLIED';
          }
        }
      })
      .addCase(getPromoCodeDiscount.rejected, (state, { payload }) => {
        state.hasError = true;
        state.isFetching = false;
        const { error } = payload as promoCodeError;
        state.promoCodeError = error;
        Sentry.captureException(
          formatTextForSentryLog('failed to get promo code discount')
        );
        return state;
      }),
  reducers: {
    resetStateSelectPackage: () => {
      return {
        packages: [],
        packagesSingleSong: [],
        isFetching: false,
        selectedPackage: undefined,
        hasError: false,
        promoCodeActive: '',
        promoCodeError: '',
      } as SelectPackageObj;
    },
    selectPlan: (state, action) => {
      state.selectedPackage = action.payload;
      if (state.selectedPackage) {
        trackEvent('Package Selected', {
          plan_id: state.selectedPackage.plan.id.toString(),
          plan_name: state.selectedPackage.plan.name,
          plan_price: state.selectedPackage.plan.price.toString(),
          release_id: state.selectedPackage.releaseId || '',
        });
      }
    },
  },
});

export const { resetStateSelectPackage, selectPlan } =
  SelectPackageSlice.actions;

export default SelectPackageSlice.reducer;
