/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal } from '@dsny/dsny-component-library';
import theme from 'src/styles/theme';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import {
  FlexButtonWrapper,
  ModalCloseButton,
  ModalContinueButton,
} from './ExtendPlanModal.styles';
import { trackEvent } from '../../../utils/segmentService';

export const ExtendPlanModal = ({ show, setShowCancel }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedSong } = useSelector(
    (state: RootState) => state.dashboard.songSelection
  );

  useEffect(() => {
    if (show) {
      trackEvent('Modal View', { modal_type: 'extend_plan_modal' });
    }
  }, [show]);

  // Getting modal text will change depending on 2 scenarios (expiration day or after)
  const getModalText = (trackingPeriod?: number, songTitle?: string) => {
    const newTrackingPeriod = trackingPeriod || 0;

    // Expiration day
    if (newTrackingPeriod === 0) {
      return (
        <Trans
          i18nKey="DASHBOARD_MODAL_EXTEND_DESC_EXPIRATION_DAY"
          values={{ song: songTitle }}
          components={{ bold: <strong /> }}
        />
      );
    }
    // After expiration day
    return (
      <Trans
        i18nKey="DASHBOARD_MODAL_EXTEND_DESC_AFTER_EXPIRATION"
        values={{
          song: songTitle,
          number: Math.abs(newTrackingPeriod),
          day: newTrackingPeriod < -1 ? 'days' : 'day',
        }}
        components={{ bold: <strong /> }}
      />
    );
  };

  const handleCancelModal = () => {
    setShowCancel(false);
    navigate(`/dashboard?songId=${selectedSong?.song_id}`);
  };

  const handleContinueModal = () => {
    setShowCancel(false);
    navigate('/payment/selectpackage');
  };

  return (
    <Modal
      isOpen={show}
      onClose={() => setShowCancel(false)}
      containerStyles={{
        width: '608px',
        padding: '32px',
      }}
    >
      <div
        className="heading"
        style={{
          fontSize: '24px',
          fontWeight: '700',
          lineHeight: '33px',
          color: theme.colors.neutralW20,
          marginBottom: '8px',
        }}
      >
        <div>{t('DASHBOARD_MODAL_EXTEND_TITLE')}</div>
      </div>

      <p
        style={{
          fontSize: '14px',
          lineHeight: '19px',
          color: theme.colors.neutralW20,
          marginBottom: '32px',
        }}
      >
        {getModalText(
          selectedSong?.selected_tracking_period?.remainingDays,
          selectedSong?.title
        )}
      </p>

      <div
        className="footer"
        style={{
          color: 'black',
        }}
      >
        <FlexButtonWrapper>
          <ModalCloseButton
            setting="mtr"
            variant="tertiary"
            fontSize="14px"
            type="button"
            onClick={handleCancelModal}
            style={{
              height: '36px',
              borderRadius: '5px',
            }}
          >
            {t('DASHBOARD_MODAL_CLOSE_CTA_BUTTON')}
          </ModalCloseButton>
          <ModalContinueButton
            setting="mtr"
            fontSize="14px"
            type="button"
            onClick={handleContinueModal}
            style={{
              fontFamily: theme.fonts.primary,
              height: '36px',
              borderRadius: '5px',
            }}
          >
            {t('DASHBOARD_MODAL_EXTEND_PLAN_CTA_BUTTON')}
          </ModalContinueButton>
        </FlexButtonWrapper>
      </div>
    </Modal>
  );
};
