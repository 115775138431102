/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@dsny/dsny-component-library';
import { useNavigate } from 'react-router-dom';
import {
  ButtonStyleWrapper,
  FlexButtonWrapper,
  HeadingImportReleaseModal,
  SubTextImportReleaseModal,
  TextImportReleaseModal,
} from './ImportRelease.styles';

export const ImportReleaseIssueModal = ({ show, setShowIssueModal }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleIssueModal = () => {
    setShowIssueModal(false);
    navigate('/dashboard');
  };

  return (
    // User without permission modal - (Import Release from Caster)
    <Modal
      isOpen={show}
      onClose={() => setShowIssueModal(true)}
      containerStyles={{
        width: '608px',
        height: 'fit-content',
        padding: '32px',
      }}
    >
      <HeadingImportReleaseModal className="heading">
        {t('IMPORT_RELEASE_ISSUE_TITLE_MODAL')}
      </HeadingImportReleaseModal>

      <TextImportReleaseModal>
        {t('IMPORT_RELEASE_ISSUE_DESC_MODAL')}
      </TextImportReleaseModal>

      <SubTextImportReleaseModal>
        {t('IMPORT_RELEASE_ISSUE_SUBDESC_MODAL')}
      </SubTextImportReleaseModal>

      <div className="footer">
        <FlexButtonWrapper>
          <ButtonStyleWrapper
            setting="mtr"
            fontSize="14px"
            type="button"
            onClick={handleIssueModal}
          >
            {t('IMPORT_RELEASE_GO_TO_DASHBOARD_CTA')}
          </ButtonStyleWrapper>
        </FlexButtonWrapper>
      </div>
    </Modal>
  );
};
