import styled from 'styled-components';
import { device } from 'src/styles/global';
import theme from 'src/styles/theme';

export const Title = styled.h2`
  padding-bottom: 8px;
  color: ${theme.colors.neutralW20};

  @media ${device.mobile} {
    font-size: 20px;
  }
`;

export const SubTitle = styled.div`
  padding-bottom: 24px;
  font-size: 14px;
  color: ${theme.colors.neutralW40};

  @media ${device.mobile} {
    padding-bottom: 24px;
  }
`;

export const SubTitleRelease = styled.div`
  padding-bottom: 24px;
`;

export const ImportantNoteWrapper = styled.div`
  padding-bottom: 24px;
  font-size: 14px;
  color: ${theme.colors.neutralW40};

  @media ${device.mobile} {
    padding-bottom: 24px;
  }
`;
