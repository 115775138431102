/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@dsny/dsny-component-library';
import { useNavigate } from 'react-router-dom';
import {
  ButtonStyleWrapper,
  FlexButtonWrapper,
  HeadingImportReleaseModal,
  SubTextImportReleaseModal,
} from './ImportRelease.styles';

export const ImportReleaseTrackedModal = ({
  show,
  setShowTrackedModal,
}: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleTrackedModal = () => {
    setShowTrackedModal(false);
    navigate('/dashboard');
  };

  return (
    // All songs are already being tracked on MTR modal - (Import Release from Caster)
    <Modal
      isOpen={show}
      onClose={() => setShowTrackedModal(true)}
      containerStyles={{
        width: '608px',
        height: 'fit-content',
        padding: '32px',
      }}
    >
      <HeadingImportReleaseModal className="heading">
        {t('IMPORT_RELEASE_TRACKED_SONGS_TITLE_MODAL')}
      </HeadingImportReleaseModal>

      <SubTextImportReleaseModal>
        {t('IMPORT_RELEASE_TRACKED_SONGS_DESC_MODAL')}
      </SubTextImportReleaseModal>

      <div className="footer">
        <FlexButtonWrapper>
          <ButtonStyleWrapper
            setting="mtr"
            fontSize="14px"
            type="button"
            onClick={handleTrackedModal}
          >
            {t('IMPORT_RELEASE_GO_TO_DASHBOARD_CTA')}
          </ButtonStyleWrapper>
        </FlexButtonWrapper>
      </div>
    </Modal>
  );
};
