/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PackageObj } from 'src/features/Purchase/SelectPackage';
import { formatAmountWithoutRoundUp } from 'src/utils/Formatter';
import {
  DiscountWrapper,
  MostPopularTag,
  PackageBox,
  PackageContainer,
  PackageWrapper,
  PerSongWrapper,
  PlanWrapper,
  PriceWrapper,
  PromoMarkdownPrice,
  SaveTag,
} from '../PricingModal.styles';

interface Props {
  packages: PackageObj[];
}

const PromoPricing: React.FC<Props> = ({ packages }) => {
  const { t } = useTranslation();

  return (
    <>
      <PackageWrapper>
        {packages.map((p, i) => {
          return (
            <PackageContainer key={p.plan.id}>
              <PackageBox $isMostPopularPackage={i === 1}>
                {i === 1 && (
                  <MostPopularTag>
                    {t('PRICING_MODAL_MOST_POPULAR')}
                  </MostPopularTag>
                )}
                <PlanWrapper>{p.plan.description}</PlanWrapper>
                <PriceWrapper>
                  $
                  {formatAmountWithoutRoundUp(p.summary.unit_price_after_promo)}
                  <PerSongWrapper>
                    &nbsp;{t('PRICING_MODAL_PER_SONG')}
                  </PerSongWrapper>
                </PriceWrapper>

                <DiscountWrapper>
                  <PromoMarkdownPrice>
                    ${formatAmountWithoutRoundUp(p.summary.unit_price)}
                  </PromoMarkdownPrice>
                  <SaveTag>
                    {' '}
                    {t('PRICING_MODAL_SAVE')}{' '}
                    {Number(p.promotion?.discount_percentage) * 100}%
                  </SaveTag>
                </DiscountWrapper>
              </PackageBox>
            </PackageContainer>
          );
        })}
      </PackageWrapper>
    </>
  );
};

export default PromoPricing;
