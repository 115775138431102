import styled from 'styled-components';
import { device, mtrStyle } from 'src/styles/global';
import theme from 'src/styles/theme';

export interface Props {
  color: string;
  backgroundColor: string;
}

export interface mobileTitleButtonProps {
  isMobileSortActive: boolean;
}

export const StationsContainer = styled.div`
  width: 50%;

  @media ${device.mobile} {
    width: 100%;
  }
  @media ${device.tablet} {
    width: 100%;
  }
  @media ${device.ipad} {
    width: 100%;
  }
`;

export const StationsBox = styled.div`
  display: flex;
  flex-direction: column;
  position: inherit;
  padding: 32px;
  border-radius: ${mtrStyle.borderRadius};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  background-color: ${theme.colors.white0};
  max-height: 578px;

  @media ${device.mobile} {
    padding: 24px;
    max-height: 548px;
  }
  @media ${device.tablet} {
    padding: 24px;
    max-height: 548px;
  }
  @media ${device.ipad} {
    padding: 24px;
    max-height: 548px;
  }
`;

export const StationswWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 40px;
  gap: 16px;

  @media ${device.mobile} {
    flex-direction: column;
    padding-bottom: 20px;
  }
  @media ${device.tablet} {
    padding-bottom: 20px;
  }
  @media ${device.ipad} {
    padding-bottom: 20px;
  }
  @media ${device.laptop} {
    padding-bottom: 20px;
  }
`;

export const FlexRowHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 8px;
  border-bottom: 1px solid ${theme.colors.neutralW90};
  gap: 16px;

  @media ${device.mobile} {
    padding-left: 0px;
  }
`;

export const TableBodyWrapper = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const FlexRowInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
  padding-top: 32px;
  gap: 16px;

  @media ${device.mobile} {
    padding-left: 0px;
  }
`;

export const FlexColumnLeftMobile = styled.div`
  width: 60%;
  text-align: left;

  @media ${device.tablet} {
    display: none;
  }

  @media ${device.ipad} {
    display: none;
  }

  @media ${device.laptop} {
    display: none;
  }

  @media ${device.desktop} {
    display: none;
  }
`;

export const FlexColumnLeft = styled.div`
  width: 40%;
  text-align: left;

  @media ${device.mobile} {
    display: none;
  }
`;

export const FlexColumnMiddle = styled.div`
  width: 30%;
  text-align: left;

  @media ${device.mobile} {
    display: none;
  }
`;

export const FlexColumnRight = styled.div`
  width: 30%;
  text-align: left;

  @media ${device.mobile} {
    width: 40%;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  @media ${device.mobile} {
    gap: 4px;
  }

  svg {
    cursor: pointer;
    width: 18px;
    height: 18px;
  }
`;

export const TitleText = styled.div`
  font-size: 14px;
  color: ${theme.colors.neutralW40};

  @media ${device.mobile} {
    font-size: 12px;
  }
`;

export const MobileTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MobileTitleButton = styled.button<mobileTitleButtonProps>`
  border: none;
  background: none;
  cursor: pointer;
  padding: 0px;
  font-size: 12px;

  color: ${({ isMobileSortActive }) =>
    isMobileSortActive ? theme.colors.neutralW40 : theme.colors.neutralW80};
`;

export const HashtagWrapper = styled.div`
  font-size: 14px;
  color: ${theme.colors.neutralW40};

  @media ${device.mobile} {
    display: none;
  }
`;

export const FlexInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  @media ${device.mobile} {
    gap: 14px;
  }
`;

export const StationInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SpinsInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const PlaysTextWrapper = styled.div`
  display: flex;
  font-size: 14px;
  color: ${theme.colors.neutralW40};

  @media ${device.mobile} {
    display: none;
  }
`;

export const ComparisonBadge = styled.div<Props>`
  display: flex;
  align-self: center;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
  padding: 1px 6px;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;
