/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal } from '@dsny/dsny-component-library';
import { useNavigate } from 'react-router-dom';
import casterRelease from 'src/assets/images/caster-release.png';
import {
  ButtonStyleWrapper,
  FlexButtonWrapper,
  HeadingImportReleaseModal,
  ReleaseImageWrapper,
  TextImportReleaseModal,
} from './ImportRelease.styles';

export const ImportReleaseCancelModal = ({
  show,
  setShowReleaseCancelModal,
}: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Handling keep editing
  const handleKeepEditingModal = () => {
    setShowReleaseCancelModal(false);
  };

  // Handling cancel and go to dashboard
  const handleCancelModal = () => {
    setShowReleaseCancelModal(false);
    navigate('/dashboard');
  };

  return (
    // Cancel modal - (Import Release from Caster)
    <Modal
      isOpen={show}
      onClose={() => setShowReleaseCancelModal(false)}
      containerStyles={{
        width: '608px',
        height: 'fit-content',
        padding: '32px',
      }}
    >
      <HeadingImportReleaseModal className="heading">
        {t('IMPORT_RELEASE_CANCEL_TITLE_MODAL')}
      </HeadingImportReleaseModal>

      <TextImportReleaseModal>
        <Trans
          i18nKey="IMPORT_RELEASE_CANCEL_DESC_MODAL"
          components={{ bold: <strong /> }}
        />
      </TextImportReleaseModal>

      <ReleaseImageWrapper>
        <img
          src={casterRelease}
          alt="Radio Background"
          style={{ width: '100%' }}
        />
      </ReleaseImageWrapper>

      <div className="footer">
        <FlexButtonWrapper>
          <ButtonStyleWrapper
            setting="mtr"
            variant="tertiary"
            fontSize="14px"
            type="button"
            onClick={handleCancelModal}
          >
            {t('IMPORT_RELEASE_CANCEL_CTA')}
          </ButtonStyleWrapper>
          <ButtonStyleWrapper
            setting="mtr"
            fontSize="14px"
            type="button"
            onClick={handleKeepEditingModal}
          >
            {t('IMPORT_RELEASE_CONTINUE_EDITING_CTA')}
          </ButtonStyleWrapper>
        </FlexButtonWrapper>
      </div>
    </Modal>
  );
};
