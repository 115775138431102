import styled from 'styled-components';
import { device } from 'src/styles/global';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${({ theme }) => theme.background.grey};
`;

// Public wrapper for footer links
export const PublicFooterWraper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px 64px;
  gap: 42px;
`;

// Private wrapper for footer links
export const PrivateFooterWraper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 64px;
  gap: 16px;

  @media ${device.tablet} {
    flex-direction: row;
    gap: 42px;
  }

  @media ${device.ipad} {
    flex-direction: row;
    gap: 42px;
  }

  @media ${device.laptop} {
    flex-direction: row;
    gap: 42px;
  }

  @media ${device.desktop} {
    flex-direction: row;
    gap: 42px;
  }
`;

// Wrapper to keep Support, Privacy, and Company links in a row on mobile and desktop
export const LinkGroupWrapper = styled.div`
  display: flex;
  gap: 42px;

  @media ${device.mobile} {
    justify-content: center;
    width: 100%;
  }
`;

export const SupportLink = styled.a`
  font-size: 14px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.neutralW40};

  &:focus-visible {
    outline: 2px solid;
  }
`;

export const StationList = styled.button`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.neutralW40};
  border: none;
  background: none;
  cursor: pointer;
  padding: 0px;

  &:focus-visible {
    outline: 2px solid;
  }
`;
