/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal } from '@dsny/dsny-component-library';
import {
  ButtonStyleWrapper,
  FlexButtonWrapper,
  HeadingImportReleaseModal,
  SubTextImportReleaseModal,
  TextImportReleaseModal,
} from './ImportRelease.styles';

type ImportReleaseEditDeleteModalProps = {
  show: boolean;
  deleteEvent?: React.MouseEvent<HTMLDivElement>;
  trackIndex?: number;
  setShowDeleteSongModal: (show: boolean) => void;
  handleTrackDelete: (
    event: React.MouseEvent<HTMLDivElement>,
    index: number
  ) => void;
};

export const ImportReleaseEditDeleteModal = ({
  show,
  deleteEvent,
  trackIndex,
  setShowDeleteSongModal,
  handleTrackDelete,
}: ImportReleaseEditDeleteModalProps) => {
  const { t } = useTranslation();

  // Handling keep song
  const handleKeepSongModal = () => {
    setShowDeleteSongModal(false);
  };

  // Handling removing song
  const handleDeleteSongModal = () => {
    setShowDeleteSongModal(false);
    if (deleteEvent !== undefined && trackIndex !== undefined) {
      handleTrackDelete(deleteEvent, trackIndex);
    }
  };

  return (
    // Delete song modal - (Import Release from Caster)
    // Navigates to dashboard after last song in the list is removed
    <Modal
      isOpen={show}
      onClose={() => setShowDeleteSongModal(false)}
      containerStyles={{
        width: '608px',
        height: 'fit-content',
        padding: '32px',
      }}
    >
      <HeadingImportReleaseModal className="heading">
        {t('IMPORT_RELEASE_DELETE_SONG_TITLE_MODAL')}
      </HeadingImportReleaseModal>

      <TextImportReleaseModal>
        <Trans
          i18nKey="IMPORT_RELEASE_DELETE_SONG_DESC_MODAL"
          components={{ bold: <strong /> }}
        />
      </TextImportReleaseModal>

      <SubTextImportReleaseModal>
        {t('IMPORT_RELEASE_DELETE_SONG_SUBDESC_MODAL')}
      </SubTextImportReleaseModal>

      <div className="footer">
        <FlexButtonWrapper>
          <ButtonStyleWrapper
            setting="mtr"
            variant="tertiary"
            fontSize="14px"
            type="button"
            onClick={handleKeepSongModal}
          >
            {t('IMPORT_RELEASE_KEEP_SONG_CTA')}
          </ButtonStyleWrapper>
          <ButtonStyleWrapper
            setting="mtr"
            fontSize="14px"
            type="button"
            onClick={handleDeleteSongModal}
          >
            {t('IMPORT_RELEASE_DELETE_SONG_CTA')}
          </ButtonStyleWrapper>
        </FlexButtonWrapper>
      </div>
    </Modal>
  );
};
