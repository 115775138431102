/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal } from '@dsny/dsny-component-library';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import { trackEvent } from 'src/utils/segmentService';
import {
  BodyWrapper,
  ChargedNote,
  Description,
  FooterWrapper,
  HeadingWrapper,
  PricingStyleButton,
} from './PricingModal.styles';
import RegularPricing from './PromoPackages/RegularPricing';
import PromoPricing from './PromoPackages/PromoPricing';
import {
  PackageObj,
  getSingleSongPackageList,
} from '../Purchase/SelectPackage';

interface PromoPriceModalProps {
  showPricingModal: boolean;
  setShowPricingModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const PricingModal = ({
  showPricingModal,
  setShowPricingModal,
}: PromoPriceModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { packagesSingleSong } = useSelector(
    (state: RootState) => state.selectPackage
  );

  const handleCancelModal = () => {
    setShowPricingModal(false);
  };

  useEffect(() => {
    dispatch(getSingleSongPackageList());
  }, []);

  useEffect(() => {
    document.body.style.overflow = showPricingModal ? 'hidden' : 'auto';
  }, [showPricingModal]);

  useEffect(() => {
    if (showPricingModal) {
      trackEvent('Modal View', { modal_type: 'pricing_modal' });
    }
  }, [showPricingModal]);

  return (
    <Modal
      isOpen={showPricingModal}
      onClose={() => setShowPricingModal(false)}
      containerStyles={{
        display: 'inline-flex',
        width: '1071px',
        height: 'fit-content',
      }}
    >
      {/* Heading */}
      <HeadingWrapper>{t('PRICING_MODAL_TITLE')}</HeadingWrapper>

      {/* Body */}
      <BodyWrapper>
        <Description>{t('PRICING_MODAL_DESC')}</Description>

        {/* Packages */}
        {packagesSingleSong &&
          (packagesSingleSong[0]?.promotion ? (
            <PromoPricing packages={packagesSingleSong as PackageObj[]} />
          ) : (
            <RegularPricing packages={packagesSingleSong as PackageObj[]} />
          ))}

        {/* Charging note */}
        <ChargedNote>
          <Trans
            i18nKey="MULTI_ASSET_CHARGE_NOTE"
            components={{ bold: <strong /> }}
          />
        </ChargedNote>
      </BodyWrapper>

      {/* Footer */}
      <FooterWrapper className="footer">
        {/* Close button */}
        <PricingStyleButton
          setting="mtr"
          type="button"
          onClick={handleCancelModal}
        >
          {t('PRICING_MODAL_CLOSE_CTA')}
        </PricingStyleButton>
      </FooterWrapper>
    </Modal>
  );
};

export default PricingModal;
