/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components';
import { device } from 'src/styles/global';
import theme from 'src/styles/theme';

export interface Props {
  color: string;
  backgroundColor: string;
}

export const OverviewContainer = styled.div`
  width: 910px;

  @media ${device.mobile} {
    width: 100%;
  }

  @media ${device.tablet} {
    width: 100%;
  }

  @media ${device.ipad} {
    width: 100%;
  }
`;

export const OverviewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 24px;
  gap: 16px;

  @media ${device.mobile} {
    flex-direction: column;
    padding-bottom: 20px;
  }

  @media ${device.tablet} {
    flex-direction: column;
    padding-bottom: 20px;
  }

  @media ${device.ipad} {
    flex-direction: column;
    padding-bottom: 20px;
  }
`;

export const StatCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;

  @media ${device.mobile} {
    flex-direction: column;
  }

  @media ${device.tablet} {
    flex-direction: column;
  }

  @media ${device.ipad} {
    flex-direction: column;
  }

  @media ${device.laptop} {
    flex-direction: column;
  }
`;

export const CardBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  border: 1px solid ${theme.colors.neutralW90};
  border-radius: 5px;
  box-sizing: border-box;
  flex-grow: 1;

  @media ${device.mobile} {
    padding: 24px;
  }
`;

export const CardFlex = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-self: center;
`;

export const MetricWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const ComparisonMetricWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
`;

export const Total = styled.div`
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  color: ${theme.colors.neutralW40};
`;

export const ComparisonBadge = styled.div<Props>`
  display: flex;
  align-self: flex-start;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
  padding: 1px 6px;
  margin-top: 5px;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const PreviousPeriod = styled.div`
  font-size: 16px;
  line-height: 21px;
  color: ${theme.colors.neutralW60};
`;
