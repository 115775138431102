import styled from 'styled-components';
import theme from 'src/styles/theme';
import { device } from 'src/styles/global';
import { Button } from '@dsny/dsny-component-library';

export interface Props {
  $isVisible?: boolean;
}

export const DiscountCodeWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const EnterPromoCodeDropdown = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  color: ${theme.colors.mtr60};
  cursor: pointer;
`;

export const FieldsWrapper = styled.div<Props>`
  display: ${({ $isVisible }) => ($isVisible ? 'flex' : 'none')};
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 16px;
  gap: 16px;

  @media ${device.mobile} {
    flex-direction: column;
    justify-content: center;
    gap: 16px;
  }
`;

export const InputField = styled.input`
  padding: 8px;
  font-size: 14px;
  border: 1px solid ${theme.colors.gray20};
  border-radius: 4px;
`;

export const ApplyButton = styled(Button)`
  width: 120px;

  @media ${device.mobile} {
    width: 100%;
  }
`;

export const CodeTagWrapper = styled.div`
  display: flex;
  padding-top: 8px;
  gap: 2px;

  @media ${device.mobile} {
    padding-top: 16px;
  }
`;

export const PillWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;

  @media ${device.mobile} {
    max-width: 184px;
  }
`;
