/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal } from '@dsny/dsny-component-library';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import {
  ButtonStyleWrapper,
  FlexButtonWrapper,
  HeadingImportReleaseModal,
  SubTextImportReleaseModal,
  TextImportReleaseModal,
} from './ImportRelease.styles';

import { PackageObj, getPackageList, selectPlan } from '../../SelectPackage';
import { deleteTrack } from '../MultiAsset.thunks';

declare type Props = {
  show: boolean;
  setShowCancel: (showModal: boolean) => void;
  songId: string;
  numberOfTracks: number;
  selectedPackage?: PackageObj;
};

export const ImportReleaseSummaryDeleteModal = ({
  show,
  setShowCancel,
  songId,
  numberOfTracks,
  selectedPackage,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const { packages } = useSelector((state: RootState) => state.selectPackage);

  // Handling delete song
  const handleDeleteSongModal = () => {
    dispatch(deleteTrack([songId]));
    dispatch(getPackageList(numberOfTracks - 1));
    setShowCancel(false);
  };

  useEffect(() => {
    const newSelectedPackage = packages.find(
      (p) => p?.plan?.id === selectedPackage?.plan?.id
    );
    dispatch(selectPlan(newSelectedPackage));
  }, [packages]);

  return (
    // Summary Delete Song Modal - (Import Release from Caster)
    <Modal
      isOpen={show}
      onClose={() => setShowCancel(false)}
      containerStyles={{
        width: '608px',
        height: 'fit-content',
        padding: '32px',
      }}
    >
      <HeadingImportReleaseModal className="heading">
        {t('IMPORT_RELEASE_DELETE_SONG_TITLE_MODAL')}
      </HeadingImportReleaseModal>

      <TextImportReleaseModal>
        <Trans
          i18nKey="IMPORT_RELEASE_DELETE_SONG_DESC_MODAL"
          components={{ bold: <strong /> }}
        />
      </TextImportReleaseModal>

      <SubTextImportReleaseModal>
        {t('IMPORT_RELEASE_DELETE_SONG_SUBDESC_MODAL')}
      </SubTextImportReleaseModal>

      <div className="footer">
        <FlexButtonWrapper>
          <ButtonStyleWrapper
            setting="mtr"
            variant="tertiary"
            fontSize="14px"
            type="button"
            onClick={() => setShowCancel(false)}
          >
            {t('IMPORT_RELEASE_KEEP_SONG_CTA')}
          </ButtonStyleWrapper>
          <ButtonStyleWrapper
            setting="mtr"
            fontSize="14px"
            type="button"
            onClick={handleDeleteSongModal}
          >
            {t('IMPORT_RELEASE_DELETE_SONG_CTA')}
          </ButtonStyleWrapper>
        </FlexButtonWrapper>
      </div>
    </Modal>
  );
};
