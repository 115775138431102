import styled from 'styled-components';
import { Button } from '@dsny/dsny-component-library';
import theme from 'src/styles/theme';
import { device } from 'src/styles/global';

export const HeadingWrapper = styled.div`
  display: inline-block;
  font-size: 36px;
  font-weight: 700;
  line-height: 49px;
  color: ${theme.colors.neutralW20};
  padding: 32px 32px 8px;

  @media ${device.mobile} {
    font-size: 24px;
    line-height: 32px;
    padding: 24px 24px 8px;
  }
`;

export const DescPromoOff = styled.span`
  color: ${theme.colors.mtr60};
`;

export const DescPromoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  line-height: 19px;
  color: ${theme.colors.neutralW40};
  padding: 0px 32px;

  @media ${device.mobile} {
    padding: 0px 24px;
  }
`;

export const NotePromoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.colors.neutralW40};
  padding: 16px 32px 0px;

  @media ${device.mobile} {
    padding: 16px 24px 0px;
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 32px;
  gap: 16px;

  @media ${device.mobile} {
    padding: 24px;
  }
`;

export const PromoPriceStyleButton = styled(Button)`
  font-size: 14px;
  min-width: 96px;
  height: 36px;
  border-radius: 5px;
`;
