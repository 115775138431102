/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal } from '@dsny/dsny-component-library';
import theme from 'src/styles/theme';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import {
  FlexButtonWrapper,
  HeadingModal,
  ModalDeleteForeverButton,
  ModalKeepCardButton,
  TextModal,
} from './SavedCard.styles';
import { MemberStatus, TeamMember } from '../AddPayment';
import { trackEvent } from '../../../utils/segmentService';

interface Props {
  show: boolean;
  setShowRemoveCard: (show: boolean) => void;
  removeSavedCard: () => void;
}

export const RemoveCardModal = ({
  show,
  setShowRemoveCard,
  removeSavedCard,
}: Props) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (show) {
      trackEvent('Modal View', { modal_type: 'remove_card_modal' });
    }
  }, [show]);

  const { memberStatus, teamMemberData }: TeamMember = useSelector(
    (state: RootState) => state.addPayment.teamMember
  );

  // Handling remove card
  const handleRemoveCard = () => {
    removeSavedCard();
    setShowRemoveCard(false);
  };

  return (
    // Remove card modal
    <Modal
      isOpen={show}
      onClose={() => setShowRemoveCard(false)}
      containerStyles={{
        width: '608px',
        height: 'fit-content',
        padding: '32px',
      }}
    >
      <HeadingModal className="heading">
        {t('DELETE_PAYMENT_MODAL_HEADING')}
      </HeadingModal>

      {/* Scenario A - user without company */}
      {memberStatus === MemberStatus.STAND_ALONE_MEMBER && (
        <TextModal>
          {t('DELETE_PAYMENT_MODAL_TEXT_USER_WITHOUT_COMPANY')}
        </TextModal>
      )}

      {/* Scenario B - user within a company */}
      {/* Scenario C - team space */}
      {(memberStatus === MemberStatus.TEAM_MEMBER ||
        memberStatus === MemberStatus.COMPANY_MEMBER) && (
        <TextModal>
          <Trans
            i18nKey="DELETE_PAYMENT_MODAL_TEXT_USER_WITH_COMPANY_OR_SHARE_SPACE"
            values={{ companyname: teamMemberData.company_name }}
          />
        </TextModal>
      )}

      <div className="footer">
        <FlexButtonWrapper>
          <ModalKeepCardButton
            setting="mtr"
            variant="tertiary"
            fontSize="14px"
            type="button"
            onClick={() => setShowRemoveCard(false)}
            style={{
              height: '36px',
              borderRadius: '5px',
            }}
          >
            {t('DELETE_PAYMENT_MODAL_KEEP_CARD_BUTTON')}
          </ModalKeepCardButton>
          <ModalDeleteForeverButton
            setting="mtr"
            fontSize="14px"
            type="button"
            onClick={handleRemoveCard}
            style={{
              fontFamily: theme.fonts.primary,
              height: '36px',
              borderRadius: '5px',
            }}
          >
            {t('DELETE_PAYMENT_MODAL_REMOVE_CARD_FOREVER_BUTTON')}
          </ModalDeleteForeverButton>
        </FlexButtonWrapper>
      </div>
    </Modal>
  );
};
