/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@dsny/dsny-component-library';
import { CUSTOMER_ZOHO_FORMS } from 'src/utils/Urls';

import theme from 'src/styles/theme';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/app/store';
import {
  ClearFilterButton,
  ClearFilterWrapper,
  CloseListButton,
  ComboboxWrapper,
  ExportDesktopWrapper,
  ExportStationListWrapper,
  FilterWrapper,
  FooterWrapper,
  HeadingWrapper,
  MissingStationWrapper,
  StationLink,
  TotalStationsWrapper,
} from './RadioStationModal.styles';
import SearchFilter from './SearchFilter/SearchFilter';
import CountrySelection from './CountrySelection/CountrySelection';
import GenreSelection from './GenreSelection/GenreSelection';
import RadioStationList from './RadioStationList/RadioStationList';
import { setClearAllFilters } from './RadioStationModal.slice';
import TotalStations from './TotalStations/TotalStations';
import ExportStationList from './ExportStationList/ExportStationList';
import { trackEvent } from '../../utils/segmentService';

const RadioStationModal = ({ showStationModal, setShowStationModal }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (showStationModal) {
      trackEvent('Modal View', { modal_type: 'radio_stations_modal' });
    }
  }, [showStationModal]);

  const handleCancelModal = () => {
    setShowStationModal(false);
  };

  // Handling clear filters button
  const handleClearFilters = () => {
    dispatch(setClearAllFilters());
  };

  if (showStationModal) {
    dispatch(setClearAllFilters());
  }

  useEffect(() => {
    document.body.style.overflow = showStationModal ? 'hidden' : 'auto';
  }, [showStationModal]);

  return (
    <Modal
      isOpen={showStationModal}
      onClose={() => setShowStationModal(false)}
      containerStyles={{
        display: 'inline-flex',
        width: 'fit-content',
        minWidth: 'fit-content',
      }}
    >
      {/* Heading */}
      <HeadingWrapper className="heading">
        <div>{t('STATIONS_TITLE')}</div>
      </HeadingWrapper>

      <FilterWrapper>
        {/* Search Filter */}
        <SearchFilter />
        <ComboboxWrapper>
          {/* Country Selection */}
          <CountrySelection />
          {/* Genre Selection */}
          <GenreSelection />
        </ComboboxWrapper>

        {/* Clear Filter Button */}
        <ClearFilterWrapper>
          <ClearFilterButton
            setting="mtr"
            type="button"
            variant="tertiary"
            onClick={handleClearFilters}
            style={{
              fontFamily: theme.fonts.primary,
              height: '36px',
              borderRadius: '5px',
            }}
          >
            {t('STATIONS_CLEAR_FILTER_CTA_BUTTON')}
          </ClearFilterButton>
        </ClearFilterWrapper>
      </FilterWrapper>

      {/* Radio Station List */}
      <RadioStationList />

      {/* Footer */}
      <FooterWrapper className="footer">
        <TotalStationsWrapper>
          <ExportStationListWrapper>
            {/* Total stations */}
            <TotalStations />
            {/* Export station list button */}
            <ExportDesktopWrapper>
              <ExportStationList />
            </ExportDesktopWrapper>
          </ExportStationListWrapper>

          {/* Missing stations link */}
          <MissingStationWrapper>
            {t('STATIONS_CANNOT_FIND')}&nbsp;
            <StationLink
              href={CUSTOMER_ZOHO_FORMS}
              target="_blank"
              rel="noreferrer"
            >
              {t('STATIONS_ADD_MISSING_STATION_LINK')}
            </StationLink>
          </MissingStationWrapper>
        </TotalStationsWrapper>
        <CloseListButton
          setting="mtr"
          type="button"
          onClick={handleCancelModal}
          style={{
            fontFamily: theme.fonts.primary,
            width: 'fit-content',
            height: 'fit-content',
            borderRadius: '5px',
          }}
        >
          {t('STATIONS_CLOSE_LIST_CTA_BUTTON')}
        </CloseListButton>
      </FooterWrapper>
    </Modal>
  );
};

export default RadioStationModal;
