import styled, { css } from 'styled-components';
import theme from 'src/styles/theme';
import { device, mtrStyle } from 'src/styles/global';

export const TrackingContainer = styled.div`
  width: 400px;

  @media ${device.mobile} {
    width: 100%;
  }

  @media ${device.tablet} {
    width: 100%;
  }

  @media ${device.ipad} {
    width: 100%;
  }
`;

export const TrackingTitle = styled.div`
  font-size: 30px;
  font-weight: 700;
  color: ${theme.colors.neutralW20};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 336px;

  @media ${device.mobile} {
    font-size: ${mtrStyle.mobileFontSize};
    width: 100%;
  }

  @media ${device.tablet} {
    font-size: ${mtrStyle.mobileFontSize};
    width: 100%;
  }

  @media ${device.ipad} {
    font-size: ${mtrStyle.mobileFontSize};
    width: 100%;
  }
`;

export const TrackingSubtitle = styled.div`
  font-size: ${mtrStyle.mobileFontSize};
  color: ${theme.colors.neutralW20};
  padding-bottom: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media ${device.mobile} {
    font-size: 16px;
  }

  @media ${device.tablet} {
    font-size: 16px;
  }

  @media ${device.ipad} {
    font-size: 16px;
  }
`;

export const PeriodTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: ${theme.colors.neutralW40};
  padding-bottom: 8px;

  @media ${device.mobile} {
    font-size: 14px;
  }

  @media ${device.tablet} {
    font-size: 14px;
  }

  @media ${device.ipad} {
    font-size: 14px;
  }
`;

const sharedStyles = css`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  border: 1px solid ${theme.colors.neutralW80};
  border-radius: ${mtrStyle.borderRadius};
  font-size: 14px;
  color: ${theme.colors.neutralW40};
  padding: 8px 16px;
  gap: 8px;

  @media ${device.tablet} {
    width: 100%;
  }

  @media ${device.ipad} {
    width: 100%;
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const PeriodWrapper = styled.div`
  ${sharedStyles}
  width: 262px;
`;

export const ScheduleWrapper = styled.div`
  ${sharedStyles}
  width: 208px;
  margin-top: 8px;
  margin-bottom: 24px;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PillStyles = {
  width: '133px',
  height: '27px',
  fontSize: '14px',
  marginBottom: '0px',
  backgroundColor: theme.colors.neutralW100,
};

export const IconContainerStyles = {
  width: '16px',
  height: '16px',
  alignItems: 'center',
};
