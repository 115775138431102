/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal } from '@dsny/dsny-component-library';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/app/store';
import {
  FlexButtonWrapper,
  HeadingDeleteSongModal,
  ModalCancelButton,
  ModalDeleteSongButton,
  TextDeleteSongModal,
} from './Purchase.styles';

import { deleteTrack } from './MultiAsset';
import { PackageObj, getPackageList, selectPlan } from './SelectPackage';
import { trackEvent } from '../../utils/segmentService';

declare type Props = {
  show: boolean;
  setShowCancel: (showModal: boolean) => void;
  songId: string;
  numberOfTracks: number;
  selectedPackage?: PackageObj;
};

export const PurchaseDeleteSongModal = ({
  show,
  setShowCancel,
  songId,
  numberOfTracks,
  selectedPackage,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const { packages } = useSelector((state: RootState) => state.selectPackage);

  useEffect(() => {
    trackEvent('Modal View', { modal_type: 'purchase_delete_song_modal' });
  }, []);

  const handleDeleteSongModal = () => {
    dispatch(deleteTrack([songId]));
    dispatch(getPackageList(numberOfTracks - 1));
    setShowCancel(false);
  };

  useEffect(() => {
    const newSelectedPackage = packages.find(
      (p) => p?.plan?.id === selectedPackage?.plan?.id
    );
    dispatch(selectPlan(newSelectedPackage));
  }, [packages]);

  return (
    <Modal
      isOpen={show}
      onClose={() => setShowCancel(false)}
      containerStyles={{
        width: '608px',
        height: 'fit-content',
        padding: '32px',
      }}
    >
      <HeadingDeleteSongModal className="heading">
        <div>{t('PURCHASE_DELETE_SONG_MODAL_TITLE')}</div>
      </HeadingDeleteSongModal>

      <TextDeleteSongModal>
        <Trans
          i18nKey="PURCHASE_DELETE_SONG_MODAL_DESC"
          components={{ bold: <strong /> }}
        />
      </TextDeleteSongModal>

      <div className="footer">
        <FlexButtonWrapper>
          <ModalCancelButton
            setting="mtr"
            variant="tertiary"
            fontSize="14px"
            type="button"
            onClick={() => setShowCancel(false)}
          >
            {t('PURCHASE_CANCEL_DELETE_SONG_MODAL_BUTTON')}
          </ModalCancelButton>
          <ModalDeleteSongButton
            setting="mtr"
            fontSize="14px"
            type="button"
            onClick={handleDeleteSongModal}
          >
            {t('PURCHASE_DELETE_SONG_MODAL_BUTTON')}
          </ModalDeleteSongButton>
        </FlexButtonWrapper>
      </div>
    </Modal>
  );
};
