import styled from 'styled-components';
import { device, mtrStyle } from 'src/styles/global';
import theme from 'src/styles/theme';

export interface Props {
  hasComparisonOn: boolean;
}

export const DashboardWrapper = styled.div`
  min-height: calc(100vh - 62px);
`;

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: auto;
  padding: 40px 24px 0px;
  max-width: 1334px; /* Dashboard max-width */

  @media ${device.mobile} {
    padding: 0px 16px 0px;
  }

  @media ${device.tablet} {
    padding: 0px 16px 0px;
  }

  @media ${device.ipad} {
    padding: 20px 16px 0px;
  }

  @media ${device.laptop} {
    padding: 232px 24px 0px;
  }

  @media ${device.desktop} {
    padding: 232px 24px 0px;
  }
`;

export const LoadingWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${theme.colors.neutralC100};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  svg {
    width: 60px;
    height: 60px;
  }
`;

export const DashboardBox = styled.div`
  display: flex;
  flex-direction: column;
  position: inherit;
  padding: 32px;
  border-radius: ${mtrStyle.borderRadius};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  background-color: ${theme.colors.white0};

  @media ${device.mobile} {
    padding: 24px;
  }
  @media ${device.tablet} {
    padding: 24px;
  }
  @media ${device.ipad} {
    padding: 24px;
  }
`;

export const DashboardTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: ${theme.colors.neutralW20};
  padding-bottom: 8px;

  @media ${device.mobile} {
    font-size: ${mtrStyle.mobileFontSize};
  }
  @media ${device.tablet} {
    font-size: ${mtrStyle.mobileFontSize};
  }
  @media ${device.ipad} {
    font-size: ${mtrStyle.mobileFontSize};
  }
`;

export const DashboardChartDesc = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const DashboardExportDataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;

  @media ${device.mobile} {
    justify-content: flex-start;
  }
  @media ${device.tablet} {
    justify-content: flex-start;
  }
`;

export const DashboardSortButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 50px;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: none;
  background: none;
  cursor: pointer;

  &:focus-visible {
    outline: 2px solid ${theme.colors.mtr60};
  }
`;

export const SelectedSongWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 24px;
  gap: 24px;

  @media ${device.mobile} {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    padding-bottom: 16px;
    gap: 16px;
  }
  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    padding-bottom: 16px;
    gap: 16px;
  }
  @media ${device.ipad} {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    padding-bottom: 16px;
    gap: 16px;
  }
`;

export const TimelineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 24px;

  @media ${device.mobile} {
    padding-bottom: 16px;
  }
  @media ${device.tablet} {
    padding-bottom: 16px;
  }
`;

export const StationLocationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 24px;
  gap: 24px;

  @media ${device.mobile} {
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    gap: 16px;
  }
  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    gap: 16px;
  }
  @media ${device.ipad} {
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    gap: 16px;
  }
`;

export const AirplayWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const NoDataAvailableWrapper = styled.div`
  align-self: center;
  padding-top: 64px;
  padding-bottom: 40px;
`;

export const ComparisonPreviousPeriodDesktop = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${theme.colors.neutralW60};
  margin-top: 4px;

  @media ${device.mobile} {
    display: none;
  }
`;

export const ComparisonPreviousPeriodMobile = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${theme.colors.neutralW60};
  margin-top: 4px;

  @media ${device.tablet} {
    display: none;
  }

  @media ${device.ipad} {
    display: none;
  }

  @media ${device.laptop} {
    display: none;
  }

  @media ${device.desktop} {
    display: none;
  }
`;

export const ComparisonTooltipWrapper = styled.div`
  background: ${theme.colors.neutralW50};
  border-radius: 5px;
  padding: 10px;
`;

export const ComparisonTooltipPeriod = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: ${theme.colors.neutralW70};
  padding-bottom: 2px;
`;

export const ComparisonTooltipData = styled.div`
  font-size: 14px;
  line-height: 19px;
  color: ${theme.colors.neutralW90};
`;

export const SecondHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  padding: 104px 24px 0px;
  max-width: 1334px; /* Dashboard max-width */
  margin: 0 auto;
  width: 100%;

  @media ${device.mobile} {
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
  }

  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    padding-top: 140px;
    padding-left: 16px;
    padding-right: 16px;
  }

  @media ${device.ipad} {
    display: flex;
    flex-direction: column;
    padding-top: 140px;
    padding-left: 16px;
    padding-right: 16px;
  }

  /* Second header */
  @media ${device.laptop} {
    position: fixed;
    top: 91px; /* Height of PrimaryHeader */
    left: 50%;
    transform: translateX(-50%);
    background-color: ${theme.colors.neutralC100};
    z-index: 99;
    max-width: 1334px; /* Dashboard max-width */
    padding: 40px 24px 16px;
  }

  /* Second header */
  @media ${device.desktop} {
    position: fixed;
    top: 91px; /* Height of PrimaryHeader */
    left: 50%;
    transform: translateX(-50%);
    background-color: ${theme.colors.neutralC100};
    z-index: 99;
    max-width: 1334px; /* Dashboard max-width */
    padding: 40px 24px 16px;
  }
`;

export const UniversalFilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;

  @media ${device.mobile} {
    display: flex;
    flex-direction: column-reverse;
    gap: 8px;
  }

  @media ${device.tablet} {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
  }

  @media ${device.ipad} {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
  }
`;

export const VerticalLine = styled.div<Props>`
  display: flex;
  height: 36px;
  border-right: 1px solid ${theme.colors.neutralW80};
  align-self: center;
  margin-bottom: ${({ hasComparisonOn }) => (hasComparisonOn ? '24px' : '0px')};

  @media ${device.mobile} {
    height: 0px;
    border-right: hidden;
    margin-bottom: 0px;
  }
`;
